import { DEVTOOLS_USER_EXPLORER_SUB_PATH } from "../config"
import { DEVTOOLS_URL } from "../config"
import { Logger } from "@hackler/javascript-sdk"
import {
  $,
  iframeId,
  shadowWrapperId,
  templates,
  togglerId,
  iframeWrapperId,
  stylesheetId,
  rootId
} from "../user-explorer/view/template"
import MessageChannelManager from "../channel/ChannelManager"
import { isCloseMessage } from "../user-explorer/message/message"
import { HackleUserExplorerBase } from "@hackler/javascript-sdk"
import { createUserExplorerChannel } from "../channel/channel"
import TransceiverImpl from "../user-explorer/message/transceiver"
import { makeDraggable } from "../user-explorer/view/utils"

const log = Logger.log

class DevtoolManager {
  private channelManager: MessageChannelManager | null = null
  constructor(private devtoolSubPath: string, private channel: (transceiver: TransceiverImpl) => void) {
    this.initialize()
  }

  private shadowRoot: ShadowRoot | undefined = undefined

  private getShadowWrapper(from?: ShadowRoot) {
    return $<HTMLElement>(`#${shadowWrapperId}`, from)
  }

  private getIFrameWrapper(from?: ShadowRoot) {
    return $<HTMLElement>(`#${iframeWrapperId}`, from)
  }

  private getToggler(from?: ShadowRoot) {
    return $<HTMLButtonElement>(`#${togglerId}`, from)
  }

  private getIFrame(from?: ShadowRoot) {
    return $<HTMLIFrameElement>(`#${iframeId}`, from)
  }

  private getTemplate(elementId: string) {
    return $<HTMLTemplateElement>(`#${elementId}-template`)
  }

  private async createMessageChannel() {
    const iframe = this.getIFrame(this.shadowRoot)
    iframe.src = `${DEVTOOLS_URL}/${this.devtoolSubPath}`

    const channelManager = await MessageChannelManager.connectTo(iframe, this.devtoolSubPath)
    if (!channelManager) {
      this.hide()
      return
    }

    this.channelManager = channelManager
    channelManager.addMessageListener(isCloseMessage, this.hide.bind(this))
    channelManager.applyChannel(this.channel)
  }

  private initialize() {
    if (typeof window === "undefined") {
      log.error("HackleDevtools called before window loaded.")
      return
    }

    if (document.getElementById(rootId)) {
      return
    }

    this.createRoot()
  }

  private show() {
    const iframeWrapperTemplate = this.getTemplate(iframeWrapperId)

    this.shadowRoot?.appendChild(iframeWrapperTemplate.content.cloneNode(true))
    this.createMessageChannel()
  }

  private createRoot() {
    const root = document.createElement("div")
    root.id = "hackle-devtools"
    Object.values(templates).forEach((template) => root.insertAdjacentHTML("beforeend", template))
    document.body.appendChild(root)

    const shadowWrapperTemplate = this.getTemplate(shadowWrapperId)
    root.appendChild(shadowWrapperTemplate.content)

    const shadowWrapperElement = this.getShadowWrapper()
    makeDraggable(shadowWrapperElement)
    const shadowDOM = shadowWrapperElement.attachShadow({ mode: "open" })
    this.shadowRoot = shadowDOM

    const togglerTemplate = this.getTemplate(togglerId)
    const stylesheet = this.getTemplate(stylesheetId)

    shadowDOM.appendChild(stylesheet.content)
    shadowDOM.appendChild(togglerTemplate.content)

    const toggler = this.getToggler(shadowDOM)
    toggler.addEventListener(
      "click",
      () => {
        if (shadowWrapperElement.classList.contains("dragged")) {
          shadowWrapperElement.classList.remove("dragged")
          return
        }

        this.show()
        toggler.classList.add("hide")
      },
      true
    )
  }

  private hide() {
    const toggler = this.getToggler(this.shadowRoot)

    toggler.classList.remove("hide")
    this.getIFrameWrapper(this.shadowRoot).remove()
  }

  public close() {
    try {
      this.channelManager?.close()

      const root = $(`#${rootId}`)
      root.remove()
    } catch (error) {
      log.error("Failed to close HackleDevtools.")
    }
  }
}

export default class HackleDevTools {
  private static devtoolInstance: DevtoolManager | null = null

  static userExplorer(userExplorer: HackleUserExplorerBase) {
    try {
      HackleDevTools.devtoolInstance = new DevtoolManager(
        DEVTOOLS_USER_EXPLORER_SUB_PATH,
        createUserExplorerChannel(userExplorer)
      )
    } catch (error) {
      log.error("Failed to initialize HackleDevTools.")
    }
  }

  static close() {
    if (!HackleDevTools.devtoolInstance) {
      log.error("There is no active HackleDevtools.")
      return
    }

    HackleDevTools.devtoolInstance.close()
  }
}
