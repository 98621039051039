export const makeDraggable = (target: HTMLElement) => {
  let isPress = false,
    prevPosX = 0,
    prevPosY = 0

  const start = (e: MouseEvent) => {
    if (isPress) return

    prevPosX = e.clientX
    prevPosY = e.clientY

    isPress = true
    window.addEventListener("mousemove", move)
  }

  const move = (e: MouseEvent) => {
    if (!isPress) return
    target.classList.add("dragged")

    const posX = prevPosX - e.clientX
    const posY = prevPosY - e.clientY

    prevPosX = e.clientX
    prevPosY = e.clientY

    target.style.left = target.offsetLeft - posX + "px"
    target.style.top = target.offsetTop - posY + "px"
  }

  const end = () => {
    isPress = false
    window.removeEventListener("mousemove", move)
  }

  target.onmousedown = start
  target.onmouseup = end
}
