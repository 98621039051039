import { Experiment, HackleUser, HackleUserExplorerBase } from "@hackler/javascript-sdk"

export enum MessageEventType {
  INIT = "HACKLE-EVENT_INIT",
  CLOSE = "HACKLE-EVENT_CLOSE",
  USER = "HACKLE-EVENT_USER",
  DECISION_AB_GET = "HACKLE-EVENT_GET_AB_DECISION",
  DECISION_FF_GET = "HACKLE-EVENT_GET_FF_DECISION",
  OVERRIDE_AB_GET = "HACKLE-EVENT_GET_AB_OVERRIDE",
  OVERRIDE_AB_SET = "HACKLE-EVENT_SET_AB_OVERRIDE",
  OVERRIDE_AB_RESET = "HACKLE-EVENT_RESET_AB_OVERRIDE",
  OVERRIDE_AB_RESET_ALL = "HACKLE-EVENT_RESET_ALL_AB_OVERRIDE",
  OVERRIDE_FF_GET = "HACKLE-EVENT_GET_FF_OVERRIDE",
  OVERRIDE_FF_SET = "HACKLE-EVENT_SET_FF_OVERRIDE",
  OVERRIDE_FF_RESET = "HACKLE-EVENT_RESET_FF_OVERRIDE",
  OVERRIDE_FF_RESET_ALL = "HACKLE-EVENT_RESET_ALL_FF_OVERRIDE"
}

export interface DevtoolsMessage {
  type: MessageEventType
  payload?: unknown
}

export interface InitMessage extends DevtoolsMessage {
  type: MessageEventType.INIT
}

export interface CloseMessage extends DevtoolsMessage {
  type: MessageEventType.CLOSE
}

export interface UserMessage extends DevtoolsMessage {
  type: MessageEventType.USER
  payload?: HackleUser
}

export interface GetABDecisionMessage extends DevtoolsMessage {
  type: MessageEventType.DECISION_AB_GET
  payload?: ReturnType<HackleUserExplorerBase["getAbTestDecisions"]>
}
export interface GetFFDecisionMessage extends DevtoolsMessage {
  type: MessageEventType.DECISION_FF_GET
  payload?: ReturnType<HackleUserExplorerBase["getFeatureFlagDecisions"]>
}

export interface GetABOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_AB_GET
  payload?: ReturnType<HackleUserExplorerBase["getAbTestOverrides"]>
}
export interface GetFFOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_FF_GET
  payload?: ReturnType<HackleUserExplorerBase["getFeatureFlagOverrides"]>
}

export interface SetABOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_AB_SET
  payload?: {
    experiment: Experiment
    variationId: number
  }
}
export interface SetFFOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_FF_SET
  payload?: {
    experiment: Experiment
    variationId: number
  }
}

export interface ResetABOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_AB_RESET
  payload?: {
    experiment: Experiment
  }
}
export interface ResetFFOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_FF_RESET
  payload?: {
    experiment: Experiment
  }
}

export interface ResetAllABOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_AB_RESET_ALL
}
export interface ResetAllFFOverrideMessage extends DevtoolsMessage {
  type: MessageEventType.OVERRIDE_FF_RESET_ALL
}

export const isDevtoolsMessage = (message: any): message is DevtoolsMessage => {
  return typeof message.type === "string" && Object.values(MessageEventType).includes(message.type)
}
export const isCloseMessage = (message: unknown): message is CloseMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.CLOSE
}
export const isInitMessage = (message: unknown): message is InitMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.INIT
}
export const isUserMessage = (message: unknown): message is UserMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.USER
}
export const isGetABDecisionMessage = (message: unknown): message is GetABDecisionMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.DECISION_AB_GET
}
export const isGetFFDecisionMessage = (message: unknown): message is GetFFDecisionMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.DECISION_FF_GET
}
export const isGetABOverrideMessage = (message: unknown): message is GetABOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_AB_GET
}
export const isGetFFOverrideMessage = (message: unknown): message is GetFFOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_FF_GET
}
export const isSetABOverrideMessage = (message: unknown): message is SetABOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_AB_SET
}
export const isSetFFOverrideMessage = (message: unknown): message is SetFFOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_FF_SET
}
export const isResetABOverrideMessage = (message: unknown): message is ResetABOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_AB_RESET
}
export const isResetFFOverrideMessage = (message: unknown): message is ResetFFOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_FF_RESET
}
export const isResetAllABOverrideMessage = (message: unknown): message is ResetAllABOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_AB_RESET_ALL
}
export const isResetAllFFOverrideMessage = (message: unknown): message is ResetAllFFOverrideMessage => {
  return isDevtoolsMessage(message) && message.type === MessageEventType.OVERRIDE_FF_RESET_ALL
}
