import { HACKLE_LOGO_URL } from "../../config"

export const $ = <T extends Element>(selector: string, from?: Element | ShadowRoot) => {
  const _from = from || document
  const elem = _from.querySelector<T>(selector)
  if (!elem) throw Error(`HackleDevtools Failed when getting element.`)

  return elem
}

export const rootId = "hackle-devtools"
export const togglerId = "hackle-devtools__toggler"
export const shadowWrapperId = "hackle-devtools__shadowWrapper"
export const iframeWrapperId = "hackle-devtools__iframeWrapper"
export const iframeId = "hackle-devtools__iframe"
export const stylesheetId = "hackle-devtools__style"

const iframeWrapper = `
  <template id="${iframeWrapperId}-template">
    <div id="${iframeWrapperId}">
      <iframe id="${iframeId}"></iframe>
    </div>
  </template>
`

const shadowWrapper = `
  <template id="${shadowWrapperId}-template">
    <div id="${shadowWrapperId}" style="position:fixed; z-index:10000001; left:24px; bottom: 61px; height: 0;"></div>
  </template>
`

const toggler = `
  <template id="${togglerId}-template">
    <button id="${togglerId}" type="button">
    </button>
  </template>

`

const stylesheet = `
  <template id="${stylesheetId}-template">
    <style id="${stylesheetId}">
      #${togglerId} {
        width: 45px;
        height: 45px;
        display: block;
        background-image: url(${HACKLE_LOGO_URL});
        background-size: contain;
        background-color: white;

        border: 2px solid white;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0px 0px 10px 3px #CACACA;
      }
      #${togglerId}.hide {
        display: none;
      }
      #${togglerId} > img {
        width: 100%;
        height: 100%;
      }
      #${iframeId} {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
      }
      #${iframeWrapperId} {
        width:420px;
        height:80%;
        min-height:580px;
        max-height:620px;
        border-radius:18px;
        position:fixed;
        left:24px;
        bottom:18px;
        animation: fade 0.5s;
        background-color: #F5F5F5;
        overflow: hidden;
        box-shadow: rgba(255, 255, 255, 0.12) 0px 0px 2px 0px inset, rgba(0, 0, 0, 0.05) 0px 0px 2px 1px, rgba(0, 0, 0, 0.3) 0px 12px 60px;
      }

      @media screen and (max-width: 768px) {
        #${iframeWrapperId} {
          width: 100vw;
          min-height: unset;
          max-height: unset;
          left: 0;
        }
      }
      @keyframes fade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
      }
    </style>
  </template>
`

export const templates = {
  iframeWrapper,
  shadowWrapper,
  toggler,
  stylesheet
}
