import { Logger } from "@hackler/javascript-sdk"
import { DevtoolsMessage, MessageEventType, isInitMessage } from "../user-explorer/message/message"
import TransceiverImpl from "../user-explorer/message/transceiver"
import { DEVTOOLS_URL } from "../config"

const log = Logger.log

type PortResponse = { port1: MessagePort | undefined; port2: MessagePort | undefined }

const CHANNEL_INIT_TIMEOUT = 5000

export default class MessageChannelManager {
  constructor(private transceiver: TransceiverImpl) {}

  static async connectTo(iframe: HTMLIFrameElement, path: string) {
    let timer: ReturnType<typeof setTimeout> | undefined
    let timeoutResolver: ((value: PortResponse) => void) | undefined
    const timeoutPromise = new Promise<PortResponse>((resolve) => {
      timeoutResolver = resolve
      timer = setTimeout(() => {
        resolve({ port1: undefined, port2: undefined })
      }, CHANNEL_INIT_TIMEOUT)
    })

    window.addEventListener("message", (e: MessageEvent) => {
      if (isInitMessage(e.data) && iframe.contentWindow) {
        const { port1, port2 } = new MessageChannel()
        iframe.contentWindow.postMessage(
          {
            type: MessageEventType.INIT
          },
          `${DEVTOOLS_URL}/${path}`,
          [port2]
        )
        clearTimeout(timer)
        timeoutResolver?.({ port1, port2 })
      }
    })

    const { port1, port2 } = await timeoutPromise

    if (!port1 || !port2) {
      log.error("Timeout occurred while loading devtools.")
      return
    }

    const transceiver = new TransceiverImpl(port1, port2)
    return new MessageChannelManager(transceiver)
  }

  public addMessageListener<T extends DevtoolsMessage>(
    messageFilter: (message: unknown) => message is T,
    listener: () => any
  ) {
    this.transceiver.receive(messageFilter, listener)
  }

  applyChannel(channel: (transceiver: TransceiverImpl) => void) {
    channel(this.transceiver)
  }

  public close() {
    this.transceiver.close()
  }
}
