import { HackleUserExplorerBase } from "@hackler/javascript-sdk"
import {
  GetABDecisionMessage,
  GetABOverrideMessage,
  GetFFDecisionMessage,
  GetFFOverrideMessage,
  MessageEventType,
  UserMessage,
  isGetABDecisionMessage,
  isGetABOverrideMessage,
  isGetFFDecisionMessage,
  isGetFFOverrideMessage,
  isResetABOverrideMessage,
  isResetAllABOverrideMessage,
  isResetAllFFOverrideMessage,
  isResetFFOverrideMessage,
  isSetABOverrideMessage,
  isSetFFOverrideMessage,
  isUserMessage
} from "../user-explorer/message/message"
import TransceiverImpl from "../user-explorer/message/transceiver"

export const createUserExplorerChannel = (userExplorer: HackleUserExplorerBase) => (transceiver: TransceiverImpl) => {
  /**
   * User Messages
   */
  transceiver.receive(isUserMessage, () => {
    const response: UserMessage = {
      type: MessageEventType.USER,
      payload: userExplorer.currentUser()
    }

    transceiver.post(response)
  })

  /**
   * A/B Test Messages
   */
  transceiver.receive(isGetABDecisionMessage, () => {
    const response: GetABDecisionMessage = {
      type: MessageEventType.DECISION_AB_GET,
      payload: userExplorer.getAbTestDecisions()
    }

    transceiver.post(response)
  })

  transceiver.receive(isGetABOverrideMessage, () => {
    const response: GetABOverrideMessage = {
      type: MessageEventType.OVERRIDE_AB_GET,
      payload: userExplorer.getAbTestOverrides()
    }

    transceiver.post(response)
  })

  transceiver.receive(isSetABOverrideMessage, (payload) => {
    if (!payload) return

    userExplorer.setAbTestOverride(payload.experiment, payload.variationId)
  })

  transceiver.receive(isResetABOverrideMessage, (payload) => {
    if (!payload) return

    userExplorer.resetAbTestOverride(payload.experiment)
  })

  transceiver.receive(isResetAllABOverrideMessage, () => {
    userExplorer.resetAllAbTestOverride()
  })

  /**
   * Feature Flag Messages
   */

  transceiver.receive(isGetFFDecisionMessage, () => {
    const response: GetFFDecisionMessage = {
      type: MessageEventType.DECISION_FF_GET,
      payload: userExplorer.getFeatureFlagDecisions()
    }

    transceiver.post(response)
  })

  transceiver.receive(isGetFFOverrideMessage, () => {
    const response: GetFFOverrideMessage = {
      type: MessageEventType.OVERRIDE_FF_GET,
      payload: userExplorer.getFeatureFlagOverrides()
    }

    transceiver.post(response)
  })

  transceiver.receive(isSetFFOverrideMessage, (payload) => {
    if (!payload) return

    userExplorer.setFeatureFlagOverride(payload.experiment, payload.variationId)
  })

  transceiver.receive(isResetFFOverrideMessage, (payload) => {
    if (!payload) return

    userExplorer.resetFeatureFlagOverride(payload.experiment)
  })

  transceiver.receive(isResetAllFFOverrideMessage, () => {
    userExplorer.resetAllFeatureFlagOverride()
  })
}
