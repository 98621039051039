import { DevtoolsMessage } from "./message"

export interface Transceiver {
  post(message: DevtoolsMessage): void

  receive<T extends DevtoolsMessage>(
    messageFilter: (message: unknown) => message is T,
    listener: (payload: T["payload"]) => any
  ): void
}

export default class TransceiverImpl implements Transceiver {
  constructor(private port: MessagePort, private opposite: MessagePort) {
    this.port.start()
  }
  receive<T extends DevtoolsMessage>(
    messageFilter: (message: unknown) => message is T,
    listener: (payload: T["payload"]) => any
  ): void {
    this.port.addEventListener("message", (e) => {
      if (messageFilter(e.data)) {
        listener(e.data.payload)
      }
    })
  }

  post(message: DevtoolsMessage): void {
    this.port.postMessage(message)
  }

  close() {
    this.port.close()
    this.opposite.close()
  }
}
